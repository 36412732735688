import React from 'react';
import InputField from '../inputFieldClass';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import InputText from '../../formElements/InputText';
import sAction from 'sAction';
import Autocomplete from '../../formElements/Autocomplete';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class DefaultRelate extends InputField {
    constructor(props) {
        super(props);
        this.state = {
            state: 'fine',
            resultOpen: false,
            searchText: '',
            id: props.def?.get('id_value'),
            value: props.value,
            keyCode: null,
            relateSelected: !!props.value,
        };
        this.input = React.createRef();
        this.autocomplete = React.createRef();
    }

    /**
     * pass keypress event to autocomplete
     * @param {number} keyCode pressed key code
     */
    callAutocomplete = (keyCode) => {
        if (this.autocomplete?.current) {
            this.autocomplete.current.keyUpHandler(keyCode);
        }
    };

    /**
     * handles key release event
     * @param {KeyboardEvent} event key release event
     */
    keyUp(event) {
        if (this.state.relateSelected) {
            this.input.current.value = this.state.value;
        }
        const keyCode = event.keyCode;
        switch (keyCode) {
            case 8: // backspace
            case 46: // delete
                if (this.state.relateSelected) {
                    this.input.current.setSelectionRange(this.input.current.value.length, this.input.current.value.length);
                    this.setState({relateSelected: false});
                }
                this.checkEmptyValue(false, event);
                break;
            case 27: // esc
                if (this.state.resultOpen === true) {
                    this.setState({resultOpen: false, keyCode: keyCode});

                    return;
                } else {
                    this.cancel();
                }
                break;
            case 38: // sipka nahoru
            case 40: // sipka dolu
                this.callAutocomplete(keyCode);
                this.setState({keyCode});

                return;
            case 13: // enter
                this.enterHandler(keyCode);

                return;
        } // end of switch

        if (!this.input || !this.input.current || !this.input.current.value || this.state.relateSelected) {
            this.setState({resultOpen: false});
        } else {
            this.setState({searchText: this.input.current.value, resultOpen: true, keyCode: keyCode});
        }
    }

    /**
     * handles enter key press
     * @param {number} keyCode enter key code
     */
    enterHandler = (keyCode) => {
        if (this.state.resultOpen) {
            this.callAutocomplete(keyCode);
        } else {
            if ((this.input?.current?.value === this.state.value) || (this.state.id !== '' && this.state.value === '')) {
                // druha podminka je pro pripad kdy kliknu na relate z hned na enter, aby se pole uzavrelo
                this.save();
            } else {
                this.setState({keyCode: keyCode});
            }
        }
    };

    /**
     * checks if input is empty and if so, loses focus accordingly
     * @param {bool} loseFocus if focus should be lost
     * @param {Event} e event to check if focus is lost after clicking on search icon
     */
    checkEmptyValue(loseFocus, e) {
        if (e && e.relatedTarget && e.relatedTarget.querySelector('.icon-detailSearch')) {
            loseFocus = false;
        }
        if (!this.input.current.value) {
            this.selectItem({id: '', value: ''}, true, loseFocus);
        }
    }

    /**
     * handle selection of related record
     * @param {string} item selected record id
     * @param {bool} doUpdate force update of the field
     * @param {bool} cancelEdit if field edit should be canceled
     */
    selectItem(item, doUpdate = true, cancelEdit = true) {
        const data = {
            way: this.props.way,
            name: this.props.def?.get('id_name'),
            fieldName: this.props.name,
            vname: this.props.def?.get('vname'),
            type: 'relate',
            value: item,
            cancelEdit,
        };
        this.setState({
            id: item.id,
            value: item.name,
            resultOpen: false,
        });
        if (this.props.way === 'report' && this.props.onBlur && item.id) {
            this.props.onBlur({id: item.id, name: item.name});

            return;
        }
        // Ackoliv to tak vypada savefield se nevola z InputFieldu ale z detailViewFieldsRelate
        this.saveField(data, doUpdate);
        if (this.props.onSelect !== undefined) {
            this.props.onSelect();
        }
    }

    /**
     * opens related record selection popup
     */
    openPopupList() {
        const self = this;
        const data = {
            module: this.props.def?.get('module'),
            selectedActive: false,
        };
        const defaultFilter = this.props.def?.get('defaultFilter');
        if (defaultFilter) {
            data.defaultFilter = defaultFilter.toJS();
        }
        sAction.openRelatePopup(data, (returnData) => {
            self.selectItem(returnData);
            if (!returnData.popupHidden) {
                sAction.popupHide();
            }
        });
    }

    /**
     * opens popup for creating new related record
     */
    newRecord = () => {
        const module = this.props.def?.get('module');
        sAction.popupDetail({
            module,
            record: '',
            saveCallback: (ret) => {
                const item = {
                    id: ret.record,
                    name: ret.name,
                };
                const data = {
                    way: this.props.way,
                    name: this.props.def?.get('id_name'),
                    fieldName: this.props.name,
                    type: 'relate',
                    value: item,
                    cancelEdit: true,
                };
                this.setState({
                    id: item.id,
                    value: item.name,
                });
                this.saveField(data, true);
                sAction.unLoad();
            },
            exitCallback: () => {},
        });
    };

    /**
     * when field is updated
     * @param {Event} e event that caused the update
     */
    onBlur(e) {
        const relTarget = e.relatedTarget;
        if (relTarget == null) {
            this.cancel();
        } else {
            const parent = relTarget.closest(
                'div[data-fieldname=\'' + this.props.name + '\']',
            );
            if (parent == null) {
                this.cancel();
            }
        }
    }

    /**
     * clear the current value
     * @param {bool} force clear value completely
     */
    deleteValue = (force = false) => {
        this.setState({relateSelected: false});
        if (!this.state.relateSelected || force) {
            this.selectItem({id: '', name: ''});
            if (this.props.way === 'report' && this.props.onBlur ) {
                this.props.onBlur({id: '', name: ''});
            }
        }
    };

    /**
     * saves the relationship to database
     * @param {string} item related record id
     */
    storeRelate = (item) =>{
        if (item) {
            this.selectItem(item, true, false);
            this.setState({resultOpen: false});
            this.input.current.value = item.name;
            if (item.selectMethod === 'click') {
                this.save();
            }

            return;
        }
        if ((this.input.current.value === this.state.value) && this.state.value) {
            this.save();

            return;
        }
        this.setState({keyCode: 13}); // KEYCODE ENTERU
    };

    /**
     * handle key press events
     * @param {KeyboardEvent} e key press event
     * @param {string} type event type
     * @param {bool} save save field value
     */
    onKeyDown(e, type = null, save = true) {
        if (e.keyCode === 9) {
            e.preventDefault();
            if (this.state.id !== '' || this.state.value === this.input?.current?.value) {
                this.input.current.value = this.state.value;
                this.save();
                super.onKeyDown(e, null, false);
            } else {
                this.setState({keyCode: e.keyCode});
            }

            return;
        }
        super.onKeyDown(e, type, save);
    }

    render() {
        const data = this.props;
        const newRecord = this.props.newRecord;
        const module = this.props.def?.get('module');
        let containerClass = 'inputEditContainer';
        const keyCode = this.state.keyCode;
        let error = false;
        if ((keyCode === 13 || keyCode === 9) && this.input.current.value !== this.state.value) {
            error = sAction.translate('LBL_SELECT_ITEM');
        }

        if (!containerClass || (containerClass && !containerClass?.includes('relateField'))) {
            containerClass += ' relateField';
        }

        let value = data.value;
        if (newRecord && (value === '' || value === undefined || value === null)) {
            value = '';
        } else {
            value = sAction.decodeHTMLEntities(value);
            value = sAction.translateCategoryName(value, module);
        }

        const containerClassField = 'inputContainer relateField';
        return (
            <div className={containerClass} data-fieldname={data.name}>
                <div className={containerClassField}>
                    {this.state.relateSelected && (
                        <div className='relatedRecordContainer'>
                            <a href={`/#detail/${module}/${this.state.id}`} target="_blank" rel="noreferrer">
                                <div className={`relatedIcon icon-${module}`}/>
                                {value}
                            </a>
                            <TooltipWrapper label={'LBL_CANCEL_ACTION'}>
                                <div onClick={() => this.deleteValue(true)} className='inputEditButton'>
                                    <div className={'icon-detailCancel'} />
                                </div>
                            </TooltipWrapper>
                        </div>
                    )}
                    <InputText
                        onKeyDown={(e) => this.onKeyDown(e)}
                        autoFocus={true}
                        onKeyUp={(event) => this.keyUp(event)}
                        onBlur={(e) => this.checkEmptyValue(true, e)}
                        myRef={this.input}
                        id={data.name}
                        defaultValue={value}
                        autoComplete="off"
                    />
                    {this.state.resultOpen ? <Autocomplete
                        data={data}
                        resultOpen={this.state.resultOpen}
                        searchText={this.state.searchText}
                        searchMethod={'relate'}
                        autocompleteReturnItem={this.storeRelate}
                        addButton={true}
                        newRecord={this.newRecord}
                        defaultFilter={data.def?.get('defaultFilter')}
                        selectedId={data.def?.get('id_value')}
                        module={data.def?.get('module')}
                        ref={this.autocomplete}
                    /> : null}
                </div>
                <div className="buttonContainer">
                    {!!error && (
                        <div
                            tabIndex="-1"
                            className="warningMessage inputEditButton"
                        >
                            <TooltipWrapper title={error} show={!!error}>
                                <div className="icon-warning" />
                            </TooltipWrapper>
                        </div>
                    )}
                    <TooltipWrapper label={'LBL_DELETE'}>
                        <div
                            tabIndex="1"
                            onClick={() => this.deleteValue()}
                            className=" inputEditButton"
                        >
                            <div className={'icon-detailCancel'} />
                        </div>
                    </TooltipWrapper>
                    <TooltipWrapper label={'LBL_LIST_SEARCH_GLOBAL'}>
                        <div
                            tabIndex="2"
                            onClick={() => this.openPopupList()}
                            className="inputEditButton"
                        >
                            <div className={'icon-detailSearch'} />
                        </div>
                    </TooltipWrapper>
                </div>
            </div>
        );
    }
}

DefaultRelate.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        def: ImmutablePropTypes.mapContains({
            get: PropTypes.func,
        }).isRequired,
    }).isRequired,
    newRecord: PropTypes.bool,
    way: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
};
