import React from 'react';
import PureComponent from '../pure';

import CalHeader from './calHeader';
import CalFilters from './calFilters';
import CalBody from './calBody';

import sAction from 'sAction';
import PropTypes from 'prop-types';

class CalPageContainer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            cssSelector: 'acmFullCalendar',
            openPicker: false,
            fullScreen: false,
            calViewType: sAction.dataGet('calendar/setting/initView') ?? 'month',
        };
    }

    /** TODO snizit pocet prenacitani:*/
    // shouldComponentUpdate(nextProps, nextState){
    //   return true
    // }

    componentDidUpdate() {
        const calViewType = this.props.data.toJS()?.calViewType;
        if (calViewType !== '') {
            this.setState({calViewType: calViewType});
        }
    }

    toggleFullScreen = () => {
        this.setState({
            fullScreen: !this.state.fullScreen,
        });

        sAction.calPlanReload('soft');
    };

    render() {
        const data = this.props.data;
        const sharedView = data.toJS().sharedView;
        const usersArr = data.setting.get('sharedIds');
        const usersNamesArr = data.setting.get('users');
        const classBoard = ' calFiltWidth';
        const curUserID = sAction.dataGet('conf/user/id');
        const load = sAction.dataGet('conf/load');
        const curUserName = usersNamesArr ? usersNamesArr[curUserID] : '';
        const calBody = [];

        if (usersArr && !usersArr.includes(curUserID)) {
            let newSettings = [];
            try {
                newSettings = this.props?.data?.setting?.toJS() ?? [];
            } catch (error) {
                newSettings = [];
            }
            newSettings.sharedIds.push(curUserID);
            sAction.saveCalSettings(newSettings, () => {
                location.reload(); // CALENDAR
            });
        }
        /**
         * if user didn't select any users IDs to shared calendar, calendar falls.
         * We recognize this event, because we have empty sharedIds, sharedView is true and loading screen is displayed
         * */
        if (usersArr && usersArr.length === 0 && sharedView === true && load) {
            console.warn('DOSLO k chybe sdileneho kalendare, je potreba vybrat ID lidi do sdileneho kalendare v nastaveni');
            console.error('DOSLO k chybe sdileneho kalendare, je potreba vybrat ID lidi do sdileneho kalendare v nastaveni');
            let newSetttings = [];
            try {
                newSetttings = this.props?.data?.setting?.toJS() ?? [];
            } catch (error) {
                newSetttings = [];
            }
            newSetttings.sharedIds = [curUserID];
            sAction.saveCalSettings(newSetttings, () => {
                location.reload(); // CALENDAR
            });
        }
        if (usersArr && sharedView) {
            if (usersArr?.includes(curUserID)) {
                calBody.push(
                    <CalBody key={'calBody' + curUserID}
                        data={data}
                        viewsNumber={usersArr?.length}
                        classBoard={classBoard}
                        cssSelector={this.state.cssSelector}
                        userName={curUserName}
                        language={this.props.language}
                        userID={curUserID}
                        sharedView={sharedView}
                        namespace={this.props.namespace}
                    />,
                );
            }
            usersArr?.forEach((userID) => {
                if (userID !== curUserID) {
                    calBody.push(
                        <CalBody key={'calBody' + userID}
                            data={data}
                            viewsNumber={usersArr?.length}
                            classBoard={classBoard}
                            cssSelector={this.state.cssSelector}
                            userName={usersNamesArr[userID]}
                            language={this.props.language}
                            sharedView={sharedView}
                            userID={userID} />,
                    );
                }
            });
        } else {
            calBody.push(
                <CalBody key={'calBody' + curUserID}
                    data={data}
                    viewsNumber={1}
                    classBoard={classBoard}
                    cssSelector={this.state.cssSelector}
                    language={this.props.language}
                    userName={curUserName}
                    sharedView={sharedView}
                    userID={curUserID} />,
            );
        }
        return (
            <div>
                <CalHeader
                    calCssSelector={'.' + this.state.cssSelector}
                    fncGoToDate={this.callGoToDate}
                    sharedView={sharedView}
                    title={data.title}
                    setting={data.setting}
                    toggleFullScreen={this.toggleFullScreen}
                    fullScreen={this.state.fullScreen}
                    calViewType={this.state.calViewType}
                />

                <div className={'calPageContainer detailCard' + (this.state.fullScreen && ' fullscreenCal')}>

                    <CalFilters data={data.filters} />

                    <div className={'calendarsContainer' + (this.state.calViewType === 'agendaDay'?' sidewise':'')}>
                        {calBody}
                    </div>

                </div>
            </div>);
    }
}

export default CalPageContainer;

CalPageContainer.propTypes = {
    data: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
};
