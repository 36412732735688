import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../pure';

import TickBox from '../formElements/TickBox';
import CalExternalEvents from './calExternalEvents';
import Button from '../formElements/Button';

import sAction from 'sAction';
import DropdownAnimation from '../animation/DropdownAnimation';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

class CalFilters extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tabSelected: sAction.dataGet('conf/user/orders_planner') === '1' ? 'events' : 'filter',
            userIsOrdersPlanner: sAction.dataGet('conf/user/orders_planner'),
        };
    }

    /**
     *
     * @param {string} name
     * @param {any} value
     */
    updateFilters(name, value) {
        sAction.dataSet('calendar/filters/' + name, value);
        // this.applyFilters();

        // propsani do nastaveni
        sAction.dataSet('calendar/setting/' + name, value);
        const calSettings = sAction.dataGet('calendar/setting').toJS();
        calSettings[name] = value;
        sAction.rest.post('saveCalSettings', calSettings, (returnData) => {
            if (!returnData) {
                sAction.error(sAction.translate('LBL_ERROR_SAVING', 'Calendar'));
            }

            sAction.calPlanReload();
        });
        if (name === 'unpackActivities') {
            sAction.calPlanReload();
        }
    }

    /**
     * applyFilters
     */
    applyFilters() {
        sAction.applyCalFilters();
    }

    componentDidUpdate() {
    }

    /**
     *
     * @param {string} field
     * @param {string} key
     * @param {any} val
     */
    updateStateFilters(field, key, val) {
        const pomStates = sAction.dataGet('calendar/filters/' + field).toJS();
        if (val) {
            pomStates.push(key);
        } else {
            const pomInd = pomStates.indexOf(key);
            pomStates.splice(pomInd, 1);
        }

        this.updateFilters(field, pomStates);
    }

    /**
     * getEventClass
     * @param {string} val
     * @returns {string}
     */
    getEventClass(val) {
        switch (val) {
            case 'Planned':
            case 'Not Started':
                return 'eventNotStarted';
            case 'Pending Input':
                return 'eventPending';
            case 'Held':
            case 'Completed':
                return 'eventSuccess';
            case 'Not Held':
            case 'Deferred':
                return 'eventFailed';
            default:
                return 'eventInProgress';
        }
    }

    /**
     * getEventIcon
     * @param {string} val
     * @returns {string}
     */
    getEventIcon(val) {
        switch (val) {
            case 'showMeetings':
                return <span className="icon-meetings calFilterIcon"></span>;
            case 'showCalls':
                return <span className="icon-calls calFilterIcon"></span>;
            case 'showTasks':
                return <span className="icon-tasks calFilterIcon"></span>;
            case 'showEvents':
                return <span className="icon-events calFilterIcon"></span>;
        }
    }

    render() {
        let classPom = 'calFilters';
        let visibClass = '';

        // TODO zkusit to zase neresit pres storage, ale vzhledem k tomu jak casto se musi vse prenacitat,
        // tak budto store, nebo to budeme muset ukladat prez BE?
        const filt = localStorage.getItem('acmCalFiltersVisibility');
        if (filt === undefined || filt === 'visible') {
            classPom += ' showFilters';
        } else {
            visibClass = ' calHidden';
        }

        const meetOptions = [];
        sAction.app_strings.meeting_status_dom.forEachObject((lbl, key) => {
            meetOptions.push({
                value: key,
                label: lbl,
            });
        });

        const callOptions = [];
        sAction.app_strings.call_status_dom.forEachObject((lbl, key) => {
            callOptions.push({
                value: key,
                label: lbl,
            });
        });

        const taskOptions = [];
        sAction.app_strings.task_status_dom.forEachObject((lbl, key) => {
            taskOptions.push({
                value: key,
                label: lbl,
            });
        });

        const data = this.props.data.toJS();

        const meetStateClass = 'filterRowState' + (!data.showMeetings ? ' calNoDisplay' : '');
        const callStateClass = 'filterRowState' + (!data.showCalls ? ' calNoDisplay' : '');
        const taskStateClass = 'filterRowState' + (!data.showTasks ? ' calNoDisplay' : '');

        const pomMeetRender = [];
        meetOptions.forEachObject((opt) => {
            pomMeetRender.push(this.generateTickBox(opt, 'meet', data));
        });

        const pomCallRender = [];
        callOptions.forEachObject((opt) => {
            pomCallRender.push(this.generateTickBox(opt, 'call', data));
        });

        const pomTaskRender = [];
        taskOptions.forEachObject((opt) => {
            pomTaskRender.push(this.generateTickBox(opt, 'task', data));
        });

        let openButtonClass = 'navFiltToogle icon-arrowLeft';
        if (this.props.data.filtersDisplayed) {
            openButtonClass = 'navFiltToogle navToogle icon-arrowRight';
        }

        const meetFilterSection = this.buildFilterSection(
            !+data.showMeetings ? ' notSelected' : '',
            meetStateClass,
            data,
            pomMeetRender,
            'showMeetings',
            'LBL_SETTINGS_MEETINGS_SHOW',
        );
        const callFilterSection = this.buildFilterSection(
            !+data.showCalls ? ' notSelected' : '',
            callStateClass,
            data,
            pomCallRender,
            'showCalls',
            'LBL_SETTINGS_CALLS_SHOW',
        );
        const taskFilterSection = this.buildFilterSection(
            !+data.showTasks ? ' notSelected' : '',
            taskStateClass,
            data,
            pomTaskRender,
            'showTasks',
            'LBL_SETTINGS_TASKS_SHOW',
        );

        const filters = [
            <div key={'calFiltersContainer'} className={'calFiltersContainer' + visibClass}>
                <div className={'calFiltersSection firstSection ' + visibClass}>
                    <div className={'calFiltersRow sectionRow' + (!+data.showCompleted ? ' notSelected' : '')}>
                        <TickBox className="calFiltersTick" name="showCompleted" checked={!!data.showCompleted}
                            onChange={(value) => this.updateFilters('showCompleted', value)}/>
                        <span className="icon-additionalActivity calFilterIcon"></span>
                        <span className="">{sAction.translate('LBL_SETTINGS_COMPLETED_SHOW', 'Calendar')}</span>
                    </div>
                </div>
                {meetFilterSection}
                {callFilterSection}
                {taskFilterSection}
            </div>,
        ];

        return (
            <div className={classPom}>
                <div key={`calBoardHeader-filters`} className={`calBoardHeader ${visibClass}`}
                    onClick={() => this.handleTabSelect('filter')}>
                    <div className="icon-CalSettingsIcon calHeaderIcon"></div>
                    <span className="calFilterTitle">&nbsp;{sAction.translate('LBL_SETTINGS', 'Calendar')}</span>
                </div>
                <div className={`calSettingSection firstSection ${visibClass}`}>
                    <div className={'calFiltersRow sectionRow' + (!+data.unpackActivities ? ' notSelected' : '')}>
                        <TickBox className="calFiltersTick" name="unpackActivities" checked={!!data.unpackActivities}
                            onChange={(value) => this.updateFilters('unpackActivities', value)}/>
                        <span className='icon-f212 calFilterIcon'></span>
                        <span className="">{sAction.translate('LBL_UNPACK_ACTIVITIES', 'Calendar')}</span>
                    </div>
                </div>
                <div className={'calBoardHeader ' + visibClass}>
                    <div key={`calBoardHeader-filters`} className="calBoardHeader"
                        onClick={() => this.handleTabSelect('filter')}>
                        <div className="icon-CalFilterIcon calHeaderIcon"></div>
                        <span className="calFilterTitle">&nbsp;{sAction.translate('LBL_FILTERS_TITLE', 'Calendar')}</span>
                    </div>
                    {this.state.userIsOrdersPlanner === '1' && (
                        <Button key={`calBoardHeader-events`} className="calBoardHeader"
                            onClick={() => this.handleTabSelect('events')}>
                            <div className="icon-calendar calHeaderIcon"></div>
                            <span className="calFilterTitle">&nbsp;{sAction.translate('LBL_EVENTS_TITLE', 'Calendar')}</span>
                        </Button>
                    )}
                </div>
                {this.state.tabSelected === 'filter' && (
                    filters
                )}
                {(this.state.tabSelected === 'events' && this.state.userIsOrdersPlanner === '1') && (
                    <CalExternalEvents key={`CalExternalEvents`} data={data} events={this.state.events}
                        reload={this.props.data.reloadExternal}/>
                )}
                <TooltipWrapper label={'LBL_TOGGLE_FILTERS'} placement={'left'}>
                    <div className={openButtonClass} onClick={() => sAction.toggleCalcFilter()}/>
                </TooltipWrapper>
            </div>
        );
    }

    /**
     *
     * @param {string} secRowClass
     * @param {string} StateClass
     * @param {any} data
     * @param {object} pomRender
     * @param {string} show
     * @param {string} lbl
     * @returns {JSX}
     */
    buildFilterSection(secRowClass, StateClass, data, pomRender, show, lbl) {
    // TODO predelat do komponenty
        return (
            <div className="calFiltersSection">
                <div className={'calFiltersRow sectionRow' + secRowClass}>
                    <TickBox
                        className="calFiltersTick"
                        name={show}
                        checked={!!data[show]}
                        onChange={(value) => this.updateFilters(show, value)}/>
                    {this.getEventIcon(show)}
                    <span className="">
                        {sAction.translate(lbl, 'Calendar')}
                    </span>
                </div>
                <DropdownAnimation open={data[show]} startingHeight={data[show]? 35 * pomRender.size + 20 : 0} >
                    <div className={StateClass}>
                        {pomRender}
                    </div>
                </DropdownAnimation>
            </div>
        );
    }

    handleTabSelect = (tabID) => {
        this.setState({tabSelected: tabID});
    };

    /**
     *
     * @param {object} opt option
     * @param {string} type module
     * @param {any} data
     * @returns {JSX}
     */
    generateTickBox(opt, type, data) {
        // get state color
        let module = type;
        let color = null;
        if (type === 'meet') module = 'meeting';
        const colors = sAction.dataGet(`calendar/${module}View/timeline/colors`);
        if (colors) {
            color = sAction.convertToPastelHSL(colors.get(opt.value));
        }

        let selVal = false;
        let itemClass = ' notSelected';
        const typeSelector = `${type}States`;
        if (data[typeSelector]?.indexOf(opt.value) >= 0) {
            selVal = true;
            itemClass = ' selected';
        }
        return (
            <div
                key={`${typeSelector}${opt.value}`}
                className={'calFiltersRow ' + this.getEventClass(opt.value) + itemClass}
                style={{
                    borderLeft: `4px solid ${(color || '#E4ECF2')}`,
                }}
            >
                <TickBox className="calFiltersStateTick"
                    checked={!!selVal}
                    onChange={(value) => this.updateStateFilters(`${typeSelector}`, opt.value, value)}/>
                <span className="calFilterLbl">{opt.label}</span>
            </div>
        );
    }
}

CalFilters.propTypes = {
    data: PropTypes.object.isRequired,
};

export default CalFilters;
