import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../pure';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import sAction from 'sAction';
import composeEmail from '../detailView/DetailViewFields/InputFields/Email/composeEmail';
import PopperAnimation from '../animation/PopperAnimation';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class NewActivityButton extends PureComponent {
    constructor() {
        super();
        this.state = {
            open: false,
        };
        this.button = React.createRef();
    }

    showEmailPopup = (e) => {
        this.setState({open: false});
        composeEmail(this.props.prefix)(e);
    };

    render() {
        const prefix = this.props.prefix;
        const optionsRender = [];
        this.props.activities.forEach((activity, index) => {
            if (activity.module === 'Emails') {
                optionsRender.push(
                    <MenuItem key={index} onClick={this.showEmailPopup} className={'newActivityOption'}>
                        <div className={'moreOptionIcon ' + activity.icon} />
                        {activity.label}
                    </MenuItem>,
                );
            } else {
                if (sAction.hasAccess(activity.module, 'newRecord')) {
                    const parent = sAction.dataGet(prefix);
                    const params = {
                        module: activity.module,
                        parentId: parent.id,
                        parentName: parent.name,
                        parentModule: parent.module,
                        prefix: prefix,
                    };
                    optionsRender.push(
                        <MenuItem key={index} className={'newActivityOption'} onClick={() => {
                            this.setState({open: false});
                            if (this.props.parent) {
                                this.props.parent.closeMaxSize();
                            }
                            sAction.newSubRecord(params);
                        }}>
                            <div className={'moreOptionIcon icon-' + activity.module} />
                            {sAction.translate('LBL_MODULE_NAME', activity.module)}
                        </MenuItem>,
                    );
                }
            }
        });

        return (
            <div>
                <TooltipWrapper label={'LBL_NEW_ACTIVITY'} disabled={this.state.open}>
                    <div
                        className="subpanelHeaderRightButton icon-addIcon"
                        ref={this.button}
                        onClick={() => this.setState({open: !this.state.open})}
                    ></div>
                </TooltipWrapper>

                <Menu
                    className="acmMoreOptionContent"
                    anchorEl={this.button.current}
                    open={this.state.open}
                    onClose={() => this.setState({open: false})}
                >
                    <PopperAnimation open={this.state.open}>
                        {optionsRender}
                    </PopperAnimation>
                </Menu>
            </div>
        );
    }
}

NewActivityButton.propTypes = {
    activities: PropTypes.array,
    data: PropTypes.any,
    parent: PropTypes.any,
    prefix: PropTypes.string,
    way: PropTypes.string,
};
